import { AfterViewInit, Directive } from '@angular/core';
import moment from 'moment';

@Directive()
export class BaseComponent implements AfterViewInit {

  breadcrumbs = [];

  constructor() {
    Array.prototype['sortBy'] = function (p) {
      return this.slice(0).sort(function (a, b) {
        return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;
      });
    };
    Array.prototype['groupBy'] = function (key) {
      return this.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    Array.prototype['remove'] = function (key, value) {
      let index = 0;
      let found = false;
      for (index = 0; index < this.length; index++) {
        if (this[index][key] === value) {
          found = true;
          break;
        }
      }
      this.splice(index, 1);
    };
  }

  sortData(sortBy, sortOrder) { }

  ngAfterViewInit() {
    this.setUpSorting();
    $('button.show-hide-panel').on('click', function (e) {
      $(e.currentTarget).toggleClass('hidden');
      $(e.currentTarget).parent().siblings('.sub-panel-body').slideToggle('slow');
    });
  }

  setUpSorting() {
    const that = this;
    $('thead').on('click', 'th', function (e) {
      const th = e.currentTarget;
      const sortBy = th.getAttribute('data-sort-by');
      let sortOrder = 'asc';
      if (sortBy) {
        if ($(th).hasClass('asc')) {
          $(th).removeClass('asc').addClass('desc');
          sortOrder = 'desc';
        } else if ($(th).hasClass('desc')) {
          $(th).removeClass('desc').addClass('asc');
        } else {
          $(th).siblings().removeClass('asc').removeClass('desc');
          $(th).addClass('asc');
        }
        that.sortData(sortBy, sortOrder);
      }
    });
  }

  startLoader() {
    $('.spinner').show();
  }

  stopLoader() {
    $('.spinner').hide();
  }

  // changeAccordianIcon(event) {
  //   const $accordian = $(event.currentTarget).find('span');
  //   if ($accordian.hasClass('glyphicon-plus')) {
  //     $accordian.removeClass('glyphicon-plus');
  //     $accordian.addClass('glyphicon-minus');
  //   } else {
  //     $accordian.removeClass('glyphicon-minus');
  //     $accordian.addClass('glyphicon-plus');
  //   }
  // }

  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static validateDate(date) {
    if(date && (moment(date, 'MM/DD/YYYY', true).isValid() || moment(date, 'YYYY-MM-DDTHH:mm:ss', true).isValid())) {
      return true;
    }
    return false;
  }

}
